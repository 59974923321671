'use client';

import React, { useState, useEffect } from 'react';
import { Typography, Stack } from '@mui/material';
import NavBox from '@ui/navBox';
import LogoutIcon from '@mui/icons-material/Logout';
import { useRouter } from 'next/navigation';
import { useLogoutAtreus } from '@wilxite-packages/wx-atreus';
import { Modal } from './modal';
import LoadingIndicator from './loadingIndicator';
import IconButton from '@mui/material/IconButton';
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import Headroom from 'react-headroom'
import { useSession } from '@utils/Session';
import { LanguageSwitcher } from '@ui/languageSwitcher';
import TimerIcon from '@mui/icons-material/Timer';
import { StopWatchObject, workingTimeSince } from '@utils/workingTimeSince';
import BookPack from './bookPackIcon';

interface HeaderProps {
  orderId?: string;
  orderStarted?: Date;
  accountCode?: string;
  actionedLines?: number;
  totalLines?: number;
  isPack?: boolean;
}

export const Header = ({ orderId, orderStarted, accountCode, actionedLines, totalLines, isPack }: HeaderProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [workingTime, setWorkingTime] = useState<StopWatchObject | null>(orderStarted?.getFullYear() ? workingTimeSince(orderStarted) : null);
  const { logoutRequest } = useLogoutAtreus();
  const router = useRouter();
  const { _ } = useLingui();
  const session = useSession();
  useEffect(() => {
    const interval = setInterval(() => {
      setWorkingTime(orderStarted?.getFullYear() ? workingTimeSince(orderStarted) : null);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  if(isLoading) {
    return(<LoadingIndicator />);
  }  

  return (
    <Headroom style={{zIndex:"1300"}}>
      <NavBox style={{textShadow: "-1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5)"}} elevation={2}>
        <Stack>
          {session?.passport.info && !orderId && !accountCode &&
            <Typography variant="h6">
              {JSON.parse(session?.passport.info).name}
            </Typography>
          }      

          {!!orderId && !!accountCode && <Stack direction={"row"} alignItems={"center"}>
          <Typography variant="h6" paddingRight={"4px" }>{isPack ? <BookPack/>: ""}</Typography>
            <Typography variant="h6">  {orderId} - {accountCode}</Typography>
            {!!totalLines && <>&nbsp;
              (<Typography variant="h6">{actionedLines}/{totalLines}</Typography>)
            </>}
            
          </Stack>}
          {!!orderStarted && !!workingTime && <Stack direction={'row'} alignItems={'center'}><TimerIcon fontSize='small' style={{marginRight:"4px"}}/><Typography variant='subtitle2'>{workingTime.hours !="00" && <>{workingTime.hours}:</>}{workingTime.mins}:{workingTime.seconds}</Typography></Stack>}
        </Stack>
        <Stack direction={'row'}>
          <LanguageSwitcher/>
          
          {session?.passport.info &&
            <IconButton style={{padding:"8px 0"}}>
              <Modal
                question={_(msg`logoutConfirmQuestion`)}
                option1={_(msg`no`)}
                option2={_(msg`yes`)}
                successOption={2}
                onSuccess={async () => {
                  setIsLoading(true);
                  logoutRequest();
                  router.refresh();
                }}
              >
                <LogoutIcon color='secondary' />
              </Modal>
            </IconButton>
          }
        </Stack>
      </NavBox>
    </Headroom>
  );
};
