'use client';

import React, { useState } from 'react';
import ReactFlagsSelect from "react-flags-select";
import { i18n } from '@lingui/core';
import styles from './styles.module.scss';
import { i18nToReactFlagsSelect, reactFlagsSelectToI18n, reactFlagsSelectCountries } from "@utils/LocaleProvider";

export function LanguageSwitcher() {
  const [selectedFlag, setSelectedFlag] = useState(i18nToReactFlagsSelect.get(i18n.locale) ? i18nToReactFlagsSelect.get(i18n.locale) : "GB");

  if (typeof window === 'undefined') {
    return;
  }

  const selectedFlagNoUndefined = selectedFlag ? selectedFlag : "GB";

  return (<div className={styles.reactFlagsSelectWrapper}><ReactFlagsSelect 
    /* space as placeholder otherwise it shows "Select language" on every render */
    placeholder=" "
    selected={selectedFlagNoUndefined}
    onSelect={(code) => {
      setSelectedFlag(code);
      let selectedLangCode = reactFlagsSelectToI18n.get(code);
      if(!selectedLangCode) {
        selectedLangCode = "en";
      }

      localStorage.setItem("selectedLangCode", selectedLangCode);
      i18n.activate(selectedLangCode);
    }}
    countries={reactFlagsSelectCountries}
    showSelectedLabel={false}
    showSecondarySelectedLabel={false}
    selectedSize={27}
    showOptionLabel={false}
    showSecondaryOptionLabel={false}
    optionsSize={36}
    className={styles.reactFlagsSelect}
  /></div>)
}