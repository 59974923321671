export type StopWatchObject = {
  hours: string | number;
  mins: string | number;
  seconds: string | number;
}

export function workingTimeSince(then: Date): StopWatchObject {
  const now = new Date();
  let minutesWorked = -1;

  const current = new Date(then);

  // Define work range
  const workHoursStart = 8;
  const workHoursEnd = 17;
  const isWorked = (time:Date): boolean => {
    return time.getDay() != 0 && time.getDay() != 6 && time.getHours() >= workHoursStart && time.getHours() < workHoursEnd && time <= now;
  }

  let minuteIncrement = 1;
  let daySkipped = false;
  for (let i = current ; i <= now ; daySkipped ? current.setTime(current.getTime() + (1000 * 60 * 60 * 24)) : current.setTime(current.getTime() + (1000 * 60 * minuteIncrement))) {
    daySkipped = false;
    
    if (isWorked(current)) {
      const extraHour = new Date(current.getTime() + (1000 * 60 * 60));
      const extraDay = new Date(current.getTime() + (1000 * 60 * 60 * 24));
      if(isWorked(extraDay)) {
        // NEED FLAG TO ADD 24 HOUR TO TIME BUT ONLY X HOURS TO WHAT WAS WORKED IN THOSE 24
        daySkipped = true;
        minuteIncrement = 60 * (workHoursEnd - workHoursStart);
      } else if(isWorked(extraHour)) {
        minuteIncrement = 60 
      } else {
        minuteIncrement = 1;
      }
      minutesWorked+=minuteIncrement;
    }
  }

  const hours = Math.floor(minutesWorked/60);
  const remainingMins = minutesWorked % 60;

  const remainingMinsString = (remainingMins < 10) ? "0" + remainingMins : remainingMins;
  const hoursString = (hours < 10) ? "0" + hours : hours;
  let secondsBit = now.getSeconds() + (60 - then.getSeconds());
  if(secondsBit >= 60) secondsBit -= 60;
  
  const secondsString = (secondsBit < 10) ? "0" + secondsBit : secondsBit;

  return {"hours":hoursString,"mins":remainingMinsString,"seconds":secondsString};
}
