'use client';

import React, { useState, useRef, useEffect } from "react";
import { Box, IconButton, Modal as MuiModal, Paper, Typography, styled } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import theme from '../styles/muiTheme';
import { Button } from './button';
import styles from './styles.module.scss';
import { useRouter } from 'next/navigation';

import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { QuantityInput } from '@ui/quantityInput';

export type ModalProps = {
  id?:string;
  question: string;
  absolute?: boolean;
  item1?: string;
  item2?: string;
  option1: string;
  option2?: string;
  successOption: 1 | 2;
  isbn?: string;
  children?: React.ReactNode[] | React.ReactNode | string;
  link?: string;
  qty?: number;
  onSuccess?: () => Promise<void>;
};

const StyledModal = styled(MuiModal)({
  position: 'fixed',
  top:0,
  padding: '1rem',
  inset: 0,
  flex: 1,
  bgcolor: 'background.paper',
  height: "100vh",
  display:"flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledPaper = styled(Paper)({
  backgroundColor: theme.palette.primary.main,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  padding: '1rem',
  minHeight: '8rem',
  margin: '8rem auto auto',
  maxHeight: "calc(100% - 8rem)",
  maxWidth: "100%",
  borderRadius: '1.5rem',
  textTransform: 'initial',
  overflow:"auto",
});

export const Modal = ({ id, question, absolute, item1, item2, option1, option2, successOption, isbn, children, link, qty, onSuccess }: ModalProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [open, setOpen] = useState(false);
  const [allowSave, setAllowSave] = useState((isbn && qty && qty > 1) ? false : true);
  const [pressedEnter, setPressedEnter] = useState(false);
  const [isbnValid, setIsbnValid] = useState(false);
  const [userIsbnInput, setUserIsbnInput] = useState<string>('');

  const router = useRouter();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { _ } = useLingui();

  useEffect(() => {
    if (pressedEnter && onSuccess) {
      handleSuccess();
      setPressedEnter(false);
    }
  }, [pressedEnter]);

  useEffect(() => {
    const characterCount = userIsbnInput.length;
    if (characterCount >= 4 && isbn?.slice(-characterCount) === userIsbnInput?.slice(-characterCount)) {
      setIsbnValid(true);
    } else {
      setIsbnValid(false);
    }
  }, [userIsbnInput]);

  useEffect(() => {
    handleChange();
  }, [allowSave]);

  useEffect(() => {
    inputRef.current?.focus();
    // THIS IS STUPID BUT WORKS FOR NOW, CAN'T GET IT TO WORK WITH POST RENDER FOCUS!!!
    // I THINK BECAUSE THE inputRef NOT ONLY WAITS FOR RENDER BUT ALSO FOR INPUT TO CALL ref AND THEN IT WOULD BE READY TO USE .focus()... TIMEOUT LETS THIS HAPPEN...
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }, [open, inputRef]);

  const handleSuccess = () => {
    if(allowSave) {
      setOpen(false);
      setUserIsbnInput('');
      setIsbnValid(false);
      if (link) {
        router.push(link);
      }
      if (isbn && onSuccess) {
        const characterCount = inputRef.current ? inputRef.current?.value.length : 0;
        if (characterCount >= 4 && isbn.slice(-characterCount) === inputRef.current?.value.slice(-characterCount)) {
          setIsbnValid(true);
          onSuccess();
        } else {
          alert(_(msg`isbnNoMatch`));
          handleClose();
        }
      }
      if (!isbn && onSuccess) {
        onSuccess();
      }
    }
  };
  const handleChange = (reset?:boolean) => {
    const value = reset ? '' : inputRef.current?.value;
    
    if (!value) {
      setUserIsbnInput('');
      return;
    }

    setUserIsbnInput(value);

    const characterCount = value.length;

    if (13 > characterCount && characterCount >= 4) {
      if (value.slice(-characterCount) === String(isbn?.slice(-characterCount))) {
        handleSuccess();
      }
    }

    if (characterCount === 13) {
      if (value === String(isbn)) {
        handleSuccess();
      }
    }
  };

  const containerBoxStyles = absolute ? {
    fontFamily: 'inherit',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    cursor:'pointer',
    position: 'absolute',
    top:0,
    bottom:0,
    left:0,
    right:0,
  } : {
    fontFamily: 'inherit',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    cursor:'pointer'
  }

  return (
    <>
      <Box
        id={id}
        onClick={(event) => {
          event.stopPropagation();
          handleOpen();
        }}
        sx={containerBoxStyles}
      >
        {children}
      </Box>
      <StyledModal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledPaper elevation={2}>
          <Typography
            variant="body1"
            sx={{ textAlign: 'center', textTransform: 'initial', maxInlineSize: '50ch', overflow: "auto", textWrap: 'balance' }}
            color={theme.palette.primary.contrastText}
          >
            {question}
          </Typography>
          <Typography
            variant="h6"
            sx={{ textAlign: 'center', textTransform: 'initial', maxInlineSize: '50ch', overflow: "auto", textWrap: 'balance', fontWeight: 900 }}
            color={theme.palette.primary.contrastText}
          >
            {item1}
          </Typography>

          {item2 && <>
            <Typography
              variant="h5"
              sx={{ textAlign: 'center', textTransform: 'initial', maxInlineSize: '50ch', overflow: "auto", textWrap: 'balance', fontWeight: 900 }}
              color={theme.palette.primary.contrastText}
            >
              {item2}
            </Typography>
          </>}

          {isbn && (<div style={{position:"relative"}}>
            <input
              ref={(inp) => {
                if (inp) {
                  inp.focus();
                  inputRef.current = inp;
                }
              }}
              // ref={inputRef}
              onChange={() => handleChange()}
              className={styles.isbnInput}
              value={userIsbnInput}
              max={9999999999999}
              maxLength={13}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              placeholder="ISBN"
              style={{
                WebkitAppearance: 'none',
                margin: "0.4rem auto",
                border: 0,
                borderBottom: '1px solid white',
                textAlign: 'center',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                MozAppearance: "textfield",
                fontSize: "1.6em",
                maxWidth: "90%",
                width:"fit-content",
                borderRadius:0,
              }}
            />
            <IconButton style={{position:"absolute",margin:"auto",right:"0",top:"0",bottom:"0",color:"white"}} onClick={() => handleChange(true)}><ClearIcon/></IconButton>
          </div>)}

          {isbn && userIsbnInput != '' && !isbnValid && (
            <Typography align='center' color={theme.palette.primary.contrastText} variant="subtitle2">
              <Trans>isbnNoMatch</Trans> ({isbn})
            </Typography>
          )}

          {qty && qty > 1 && <QuantityInput qty={qty} setAllowSave={setAllowSave} setPressedEnter={setPressedEnter} />}

          <Box
            sx={{
              mt: '.8rem',
              paddingInline: '1rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              width: '100%',
            }}
          >
            <Button
              label={option1}
              key="1"
              secondary
              size="large"
              onClick={() => (successOption == 1 ? handleSuccess() : handleClose())}
            />
            {option2 &&
              <Button
                label={option2}
                key="2"
                secondary
                size="large"
                onClick={() => (successOption == 2 ? handleSuccess() : handleClose())}
              />
            }
          </Box>
        </StyledPaper>
      </StyledModal>
    </>
  );
};
