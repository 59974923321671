import { CircularProgress } from '@mui/material';
import React from 'react';
import styles from './styles.module.scss';

export default function loadingIndicator() {
  return (
    <div className={styles.loading}>
      <CircularProgress />
    </div>
  );
}
