'use client';

import { createContext, useContext } from 'react';
import { Session } from '@lib/auth';

type SessionProviderProps = {
  children: React.ReactNode;
  session?: Session;
};

const SessionContext = createContext<Session | undefined>(undefined);

const SessionProvider = ({ children, session }: SessionProviderProps) => {
  return <SessionContext.Provider value={session}>{children}</SessionContext.Provider>;
};

const useSession = () => {
  return useContext(SessionContext);
};

export { SessionProvider, useSession };
