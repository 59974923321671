'use client';

import { useEffect } from 'react';

import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { messages as enMessages } from "@locales/en/messages";
import { messages as itMessages } from "@locales/it/messages";
import { messages as ptMessages } from "@locales/pt/messages";
import { messages as roMessages } from "@locales/ro/messages";
import { messages as plMessages } from "@locales/pl/messages";
import { messages as eeMessages } from "@locales/ee/messages";
import { messages as huMessages } from "@locales/hu/messages";
import { messages as frMessages } from "@locales/fr/messages";
import { messages as csMessages } from "@locales/cs/messages";

i18n.load({
    // 'cs': csMessages,
    'en': enMessages,
    'it': itMessages,
    'pt': ptMessages,
    'ro': roMessages,
    'pl': plMessages,
    'ee': eeMessages,
    'hu': huMessages,
    // 'fr': frMessages,
});


export const i18nToReactFlagsSelect = new Map<string, string>([
    ["en", "GB"],
    ["it", "IT"],
    ["pt", "PT"],
    ["ro", "RO"],
    ["pl", "PL"],
    ["ee", "EE"],
    ["hu", "HU"],
    // ["fr", "FR"],
    // ["cs", "CZ"],
]);

export const reactFlagsSelectToI18n = new Map<string, string>([
    ["GB", "en"],
    ["IT", "it"],
    ["PT", "pt"],
    ["RO", "ro"],
    ["PL", "pl"],
    ["EE", "ee"],
    ["HU", "hu"],
    // ["FR", "fr"],
    // ["CZ", "cs"],
]);

export const reactFlagsSelectCountries = Array.from( i18nToReactFlagsSelect.values() );

const LocaleProvider = ({ children }: { children: React.ReactNode }) => {
    useEffect(() => {
        i18n.activate(localStoreLangCode);
    }, []);

    if (typeof window === 'undefined') {
        return;
    }
    let localSelectedLangCode = localStorage.getItem("selectedLangCode");

    if(!localSelectedLangCode) {
        localSelectedLangCode = "en";
    }

    /// this feels silly...
    const localStoreLangCode = (localStorage.getItem("selectedLangCode") && i18nToReactFlagsSelect.get(localSelectedLangCode)) ? localSelectedLangCode : "en";

    return <I18nProvider i18n={i18n}>
        {children}
    </I18nProvider>
}

export default LocaleProvider;