import { SVGProps } from "react"


const BookPack = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="`20`" height="20" style={{display: 'block',margin:'0 auto'}}>
            <path d="M575.1 443.3L461.5 19.1C458.2 6.7 445.6-3.2 430.2 1L414.7 5.1c-6.2 1.7-11.5 6.4-16.1 14.2-14 6.9-52.3 17.2-68 18.2-7.8-4.5-14.9-6-21-4.3l-15.5 4.1c-2.4 .7-4.2 2-6.2 3.1V32c0-17.7-14.3-32-32-32h-64c-17.7 0-32 14.3-32 32v64h128l101.7 396.9c3.3 12.4 15.9 22.2 31.4 18.1l15.5-4.1c6.2-1.7 11.5-6.4 16.1-14.2 13.9-6.9 52.2-17.2 68-18.2 7.8 4.5 14.9 6 21 4.3l15.5-4.1c15.5-4.1 21.4-19 18.1-31.4zm-134.4-7.1L348.6 92.4l61.8-16.6 92.1 343.8-61.8 16.6zM0 384h128V128H0v256zM96 0H32C14.3 0 0 14.3 0 32v64h128V32c0-17.7-14.3-32-32-32zM0 480c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32v-64H0v64zm160-96h128V128H160v256zm0 96c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32v-64H160v64z"/></svg>
    )
  }
  
  export default BookPack;