import theme from '@styles/muiTheme';

import { Paper, styled } from '@mui/material';

export const NavBox = styled(Paper)({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '.5rem 1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 0,
    width: '100%',
    inset: '0 0 auto 0',
});

export default NavBox;